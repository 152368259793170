import { getBeforeDate, getDateStr } from '@/js/utils/date'

const state = {
  // HH:mm:ssを00:00:00で揃える為
  date: {
    startDate: getBeforeDate(getDateStr(new Date(), 'YYYY-MM-DD'), 31),
    endDate: getBeforeDate(getDateStr(new Date(), 'YYYY-MM-DD'), 1),
  },
}

const mutations = {
  setStartDate(state, startDate) {
    state.date.startDate = startDate
  },
  setEndDate(state, endDate) {
    state.date.endDate = endDate
  },
}

const getters = {
  startDate(state) {
    return state.date.startDate
  },
  endDate(state) {
    return state.date.endDate
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
