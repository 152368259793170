<template>
  <header class="header">
    <div class="header_search">
      <search />
    </div>
    <div class="header_btns">
      <tool-tip-button
        icon="language"
        :useBorder="false"
        width="130px"
        data-test="header-nav-language"
      >
        <nav class="tool_tip_nav">
          <ul>
            <li
              v-for="(lang, index) in langOption"
              :key="index"
              @click="changeLang(lang.value)"
              :data-test="'header-nav-language-' + lang.value"
            >
              <button :class="{ blank: !checkLang(lang.value) }">
                <icon type="done" color="color-white" size="16" v-if="checkLang(lang.value)"></icon>
                {{ lang.label }}
              </button>
            </li>
          </ul>
        </nav>
      </tool-tip-button>
      <tool-tip-button
        icon="person"
        :useBorder="false"
        width="200px"
        :onHold="logoutLoading"
        data-test="header-nav-user"
      >
        <nav class="tool_tip_nav">
          <ul>
            <li
              :class="{ disable_li: disablePasswordNav }"
              @click="togglePasswordDialog"
              data-test="header-nav-item-change-password"
            >
              <hover-tool-tip :tool-tip-text="noPermissionText" :width="210">
                <div :class="{ disable_menu: disablePasswordNav }">
                  {{ $t('header_nav_main.change_password') }}
                </div>
              </hover-tool-tip>
            </li>
            <li @click="toggleUserPermissionDialog" data-test="header-nav-item-user-permission">
              {{ $t('header_nav_main.check_privileges') }}
            </li>
            <!-- #2930のバージョンアップまで非活性化-->
            <li
              class="disable_li disable_menu"
              v-if="hasChildUser"
              data-test="header-nav-item-child-users"
            >
              <!-- <router-link to="/child_users">-->
              <div>
                {{ $t('header_nav_main.child_account_management') }}
                <icon class="icon" type="keyboard_arrow_right" />
              </div>
              <!--</router-link>-->
            </li>
            <li v-if="showDebugNav">
              <router-link to="/debug"> 開発者用デバッグ </router-link>
            </li>
            <li class="logout" @click="logout" data-test="header-nav-item-logout">
              <span>{{ $t('header_nav_main.logout') }}</span>
              <span v-if="logoutLoading" class="spinner">
                <spinner color="color-white" size="20px" />
              </span>
            </li>
          </ul>
        </nav>
      </tool-tip-button>
      <div class="header_btns_notifications" :class="{ unread: hasUnreadNotifications }">
        <span class="notification_tooltip">
          <router-link to="/notification">
            <icon-button
              icon="notifications"
              :useBorder="false"
              data-test="header-nav-notifications"
            />
          </router-link>
          <!-- 通知のツールチップ-->
          <notification-tool-tip />
        </span>
      </div>
    </div>
    <!-- ユーザー権限 ダイアログ-->
    <user-permissions-dialog v-model="displayUserPermissionsDialogFlag" />
    <!-- パスワード変更-->
    <password-dialog
      v-model="displayPasswordDialogFlag"
      @close="togglePasswordDialog"
    ></password-dialog>
  </header>
</template>

<script>
import ToolTipButton from '@/components/organisms/ToolTipButton'
import Spinner from '@/components/atoms/Spinner'
import PasswordDialog from './components/PasswordDialog'
import UserPermissionsDialog from './components/UserPermissionsDialog'
import Search from './components/Search'
import { mapGetters, mapActions } from 'vuex'
import Icon from '@/components/atoms/Icon'
import APP from '@/js/const/app'
import IconButton from '@/components/molecules/IconButton'
import NotificationToolTip from './components/NotificationToolTip'
import HoverToolTip from '@/components/molecules/HoverToolTip'
import i18n from '@/plugins/i18n'

export default {
  name: 'HeaderNav',
  components: {
    HoverToolTip,
    NotificationToolTip,
    IconButton,
    Icon,
    UserPermissionsDialog,
    Spinner,
    ToolTipButton,
    Search,
    PasswordDialog,
  },
  data() {
    return {
      logoutLoading: false,
      displayPasswordDialogFlag: false,
      displayUserPermissionsDialogFlag: false,
      displayAppInfoDialogFlag: false,
      notifications: [],
      isShowChildUsersNav: false,
      langOption: APP.SELECT_LANGUAGE_OPTIONS,
    }
  },
  created() {
    this.getTags()
  },
  computed: {
    ...mapGetters({
      permissions: 'auth/permissions',
      hasUnreadNotifications: 'notification/hasUnreadNotifications',
      readState: 'notification/readState',
      hasChildUser: 'auth/hasChildUser',
    }),
    /**
     * パスワード変更のナビゲーション表示
     */
    disablePasswordNav() {
      // #2930のバージョンアップまで非活性化
      return true
      // return !(this.permissions && this.permissions.modPassword)
    },
    /**
     * 権限ないメッセージ表示
     */
    noPermissionText() {
      return this.disablePasswordNav ? i18n.global.t('header_nav_main.no_permission') : ''
    },
    checkLang() {
      return (lang) => {
        return lang === this.$store.getters['app/langInfo']
      }
    },
    /**
     * 開発者用デバッグ画面ナビゲーションの表示・非表示
     */
    showDebugNav() {
      return this.permissions.debug
    },
  },
  methods: {
    ...mapActions({
      getTags: 'tag/getTags',
      authLogout: 'auth/logout',
    }),
    /**
     * 言語変更
     * @param {String} lang 言語データ
     */
    changeLang(lang) {
      this.$store.commit('app/setLang', lang)
    },
    togglePasswordDialog() {
      if (this.disablePasswordNav) {
        return
      }
      this.displayPasswordDialogFlag = !this.displayPasswordDialogFlag
    },
    toggleUserPermissionDialog() {
      this.displayUserPermissionsDialogFlag = !this.displayUserPermissionsDialogFlag
    },
    /**
     * ログアウト
     */
    logout() {
      this.logoutLoading = true
      this.authLogout().then(
        () => {
          this.$router.push({ name: 'login' })
          this.logoutLoading = false
        },
        () => {
          this.logoutLoading = false
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  z-index: 5;
  @include shadow();
  height: $header-height;
  width: 100%;
  background: #fff;
  display: flex;
  padding: $size-s $size-m;
  @include sm {
    /* 60px = $sidenav-btn-width */
    width: calc(100% - 60px);
    margin-left: $sidenav-btn-width;
  }
  @media print {
    display: none;
  }
}
.header_search {
  @include sm {
    position: absolute;
    left: $size-s $size-m;
    top: $size-s;
    z-index: 21;
  }
}
.header_btns {
  margin-left: auto;
  display: flex;
}
.header_btns_notifications {
  position: relative;

  &.unread {
    @include badge;
  }
}
.tool_tip_nav {
  li {
    cursor: pointer;
    color: $color-white;
    i {
      color: $color-white;
    }
    &:not(:last-child) {
      margin-bottom: $size-m;
    }
    & > * {
      color: #fff;
      width: 100%;
      text-align: left;
      @include fs($font-size-5);
    }
  }
  .icon {
    display: inline;
    margin-left: $size-s;
  }
}
.logout {
  display: flex;
  align-items: center;
  .spinner {
    width: 20px;
    height: 20px;
    margin-right: $size-s;
  }
}
.blank {
  padding-left: 20px;
}
.disable_menu {
  opacity: 0.3;
}
.disable_li {
  cursor: not-allowed !important;
}
</style>
